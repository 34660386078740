import { List as StyledList, ListItem } from './List.styled';

import type { FC } from 'react';
import type { Size } from 'styled/types';
import type { Clause } from './types';

export interface ListProps {
  items: Clause[];
  isNumbered?: boolean;
  isBulleted?: boolean;
  /** Vertical spacing between each child */
  itemGap?: Size;
  noIndent?: boolean;
  /** Space between bullet/number and text */
  bulletPadding?: Size;
}

/**
 * This can either be a bulleted (\<ul>) or numbered (\<ol>) list depending on props.isNumbered
 *
 * For standard list usages like
 *
 * <ol>
 *  <li>Item 1</li>
 *  <li>Item 2</li>
 * </ol>
 *
 * or
 *
 * <ul>
 *  <li>Item 1</li>
 *  <li>Item 2</li>
 * </ul>
 *
 * @param {ListProps} props
 * @param {boolean} props.isNumbered - false = <ul>; true = <ol>
 */
export const List: FC<ListProps> = ({
  items,
  isNumbered = false,
  isBulleted = true,
  noIndent = false,
  itemGap = 1,
  bulletPadding = 0,
}) => (
  <StyledList as={isNumbered ? 'ol' : 'ul'} noIndent={noIndent}>
    {items.map(({ id, content }) => (
      <ListItem
        key={id}
        isNumbered={isNumbered}
        isBulleted={isBulleted}
        itemGap={itemGap}
        bulletPadding={bulletPadding}
      >
        {content}
      </ListItem>
    ))}
  </StyledList>
);
