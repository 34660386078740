import styled, { css } from 'styled-components';
import { math } from 'polished';

import { removeLastChildMarginBottomMixin } from 'styled/helpers/removeLastChildMarginBottom';
import { getDimension } from 'styled/helpers/getDimension';

import type { Size, Theme } from 'styled/types';

interface ListProps {
  noIndent: boolean;
  as: 'ul' | 'ol';
}

export const List = styled.ul<ListProps>`
  margin-top: 0;

  ${props =>
    props.noIndent &&
    css`
      padding-left: 0;
      margin-left: ${props.theme.components.list.leftMargin[props.as]};
    `}

  ${removeLastChildMarginBottomMixin}
`;

interface ListItemProps {
  itemGap: Size;
  bulletPadding: Size;
  isNumbered: boolean;
  isBulleted: boolean;
}

/*
 * Gap between bullet/number and text
 *
 * In numbered lists, we add a little bit more padding - when bulletPadding is 0 (false),
 * numbers look too close to text, so we increase the padding of all sizes slightly.
 */
const getListItemPaddingLeft = (bulletPadding: Size, isNumbered: boolean, theme: Theme): string => {
  const padding = getDimension(`size${bulletPadding}`)({ theme });

  if (!isNumbered) return padding;
  const offset = theme.components.list.bulletPaddingOffset;

  return math(`${padding} + ${offset}`);
};

/**
 * General styling - we cannot put this in global styles as it messes with styling
 * in many other places that use <li>, such as the navigation bar.
 */
export const ListItem = styled.li<ListItemProps>`
  margin-bottom: ${props => getDimension(`size${props.itemGap}`)};

  line-height: ${props => props.theme.baseText.lineHeight};

  ${props => css`
    padding-left: ${getListItemPaddingLeft(props.bulletPadding, props.isNumbered, props.theme)};
  `}

  ${({ isBulleted }) =>
    !isBulleted &&
    css`
      list-style: none;
    `}
`;
